
import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import omit from "lodash/omit";
import { LineItem, ResultPaginate } from "../../../../interfaces/line_item";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
import { mapActions, mapGetters } from "vuex";
import {
	prepareTableData,
	prepareSelectedData,
	prepareTableContent,
	preparedColumnDefsGrid,
} from "../../../../utils/CustomizeViewData";
import { Paginated, SortingOption } from "../../../../interfaces/paginated";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CloneEntity from "../../Commons/DuplicatedEntity.vue";
// @ts-ignore
import { MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import CustomizeRowGrid from "@/components/Header/Tables/CustomizeRowGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import ConfirmDelete from "@/views/Admin/Commons/ConfirmDelete.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import BulkEntity from "@/models/Bulk";
//@ts-ignore
import { getPermisionLineItem } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "LineItemList",
	props: {},
	components: {
		Buttons,
		SnackBar,
		TableList,
		Alertize,
		Dialog,
		CloneEntity,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		CustomizeRowGrid,
		ConfirmDelete,
		NotPermission
	},
	data: () => ({
		title: "List",
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption(),
		table: {
			headers: [],
			items: [],
		},
		isClone: false,
		fields: {},
		entityDuplicated: undefined,
		optionsDuplicated: {},
		openDuplicated: false,
		bulk: new BulkEntity(true),
		columnRestored: false,
		context: null,
		selectionRows: "multiple",
		optionDelete:{
			id:null,
			open:false,
			err: false,
			entity: "Line Items",
			msg: "",
			sub_msg:""
		}
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		await this.setFiltersFromURLParams();
		await this.verifyFilters();
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("profile", ["isRolReport", "getAbility"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("breadcrumbsM", [
			"getNavegationData",
		]),
		...mapGetters("internationalization", ["getLanguage"]),

		getTo(){
			return this.getNavegationData("to");
		},

		isReadOnly(): Boolean {
			return this.isRolReport;
		},

		hasCampaignID() {
			return !!this.$route.query.campaign_id;
		},
		getCampaignID() {
			return Number(this.$route.query.campaign_id);
		},
		hasAdvertiserID() {
			return !!this.$route.query.advertiser_id;
		},
		getAdvertiserID() {
			return Number(this.$route.query.advertiser_id);
		},
		hasCreativeID() {
			return !!this.$route.query.creative_id;
		},
		getCreativeID() {
			return Number(this.$route.query.creative_id);
		},
		getResultPaginate(): ResultPaginate {
			return this.$store.state.line_item.result_paginate;
		},
		getEntities(): LineItem[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},
		getSize() {
			return this.$t("show.line_items", {
				total: this.getEntities.length,
			});
		},
		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},
		getConfigColumnDef() {
			return {
				context: this.context,
				eventActive: true,
				entityName: "Line Item",
				redirect: "LineItemEdit",
				activityLog: "ActivityLogLineItem",
				edit: true,
				duplicated: this.isReadOnly ? false: true,
				log: this.isReadOnly ? false: true,
				redirecCustom: { to: "LineItemEdit", isColumn: true, parameter: "params", isInvoke: false },
				deleteAction:true,
				headerCheckboxSelection: this.canCheck,
				checkboxSelection: this.canCheck,
				readonly: this.isReadOnly,
				permission: this.getPermission
			};
		},
		getFiltrosAplicados() {
			const newFilters = this.getFiltersEntity("lineItemFilter").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},
		getOptionsAplicados() {
			return this.getFiltersEntity("lineItemFilter").options;
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.table.headers,
				this.getConfigColumnDef
			);
		},

		gerRowsData() {
			if (isEmpty(this.table.items)) {
				return [];
			} else {
				return this.table.items;
			}
		},

		canCheck(){
			return this.getAbility.can(this.getPermission.actions.active, this.getPermission.subject);
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionLineItem();
		}
	},
	async destroyed(){
		await this.resetFilter();
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("line_item", ["deleteLineItemByID"]),
		/** Begin Customize View **/

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected"
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},
		...mapActions("proccess", ["setNotification"]),

		...mapActions("loading", ["setLoadingData"]),

		...mapActions("line_item", ["setActive"]),

		...mapActions("clone_entity", ["fetchCloneEntity"]),

		openDialogDuplicated(params: any) {
			this.entityDuplicated = params.id;
			this.getOptionsDuplicated(params.id);
			this.openDuplicated = true;
		},

		closeDialogDuplicated() {
			this.entityDuplicated = undefined;
			this.openDuplicated = false;
		},

		getOptionsDuplicated(id: number) {
			this.optionsDuplicated = this.getEntities.find(
				(c) => c.id == id
			).clonable;
		},

		getMsgError(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Error",
				message: message,
				type: MessageTypes.ERROR,
				show: true,
				details: "",
				to: redirec ? to : undefined,
				btn_text: "Close",
			};
			return msgSucces;
		},

		async handleDuplicated(params: any) {
			this.isClone = true;
			await this.fetchCloneEntity({
				key: "lineItem",
				data: params,
			}).catch((err) => {
				this.isClone = false;
				this.closeDialogDuplicated();
				this.setNotification(this.getMsgError(false, "", err.message));
			});
			this.isClone = false;
			this.closeDialogDuplicated();
			await this.updateParams(
				{
					filters: this.filters,
					options: this.options,
				},
				false
			);
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach((element) => {
				let col = this.table.headers.filter(
					(c) => c.value == element.col
				);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity,
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async updateItemsTable() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			if(!isEmpty(selected)){
				this.fields = await prepareSelectedData(selected);

				this.table.items = await prepareTableContent(
					this.fields,
					this.getEntities
				);
				this.$forceUpdate();
			}

		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getEntities,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "lineItem",
				entities: this.getEntities,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/
		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.$store.dispatch("line_item/paginated", {
					paginated: this.paginated,
					filters: this.filters,
					options: this.options,
				});
				if (uTable) {
					await this.getTableData({ view: this.$route?.name });
				} else {
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},
		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async updateParams(
			params: { filters: any; options: SortingOption },
			uTable: boolean = true
		) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},
		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
			await this.saveFilters();
		},
		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams(
				{
					filters: this.filters,
					options: params.options,
				},
				false
			);
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			await this.resetFilter();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async saveFilters() {
			await this.saveFiltersEntity({
				view: "lineItemFilter",
				filters: { filters: this.filters, options: this.options },
			});
		},
		async resetFilter() {
			await this.resetFilterEntity({
				view: "lineItemFilter",
				filters: { filters: {}, options: {} },
			});
		},
		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams(
				{
					filters: newFilters,
					options: this.options,
				},
				false
			);
			await this.saveFilters();
			//this.$router.push(this.$route.path);
		},

		async handleActive(event: any) {
			await this.setLoadingData(
				event.active ? TypeLoading.enable : TypeLoading.disable
			);

			const reponse = await this.setActive({
				id: event.id,
				active: event.active,
			}).catch(async (error: any) => {
				this.setUpdateLineItem(
					event.id,
					true,
					!event.active,
					event.rowIndex
				);
				await this.setLoadingData();
			});
			this.setUpdateLineItem(event.id, false, reponse, null);
			await this.setLoadingData();
		},

		setUpdateLineItem(
			id: number,
			err: boolean,
			entity: any,
			rowIndex: any
		) {
			if (err) {
				this.$refs.Grid_Line.updateRowNodeByIndex(rowIndex, entity);
			} else {
				const item = this.table.items.find(
					(v: { id: number }) => v.id == id
				);

				if(item) {
					item.active = entity.active
				}
			}
		},

		handleSelectedIds(params: Array<any>) {
			this.bulk.selected = params;
		},

		handleBulk(bulk: BulkEntity) {
			this.bulk = bulk;
			this.updateItemsTable();
		},

		handleActiveBulk() {},
		
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},

		async checkIDs(){
			if(
				this.hasCampaignID ||
				this.hasAdvertiserID ||
				this.hasCreativeID
			) {
				if (this.hasCampaignID) {
					this.filters.campaign = { id: this.getCampaignID };
				}
				if (this.hasAdvertiserID) {
					this.filters.advertiser_id = this.getAdvertiserID;
				}
				if (this.hasCreativeID) {
					this.filters.creative_id = this.getCreativeID;
				}
				return true;
			}
			return false;
		},

		async setFiltersFromURLParams() {
			if (await this.checkIDs()) {
				
				await this.resetFilter();
				await this.saveFilters();
			}
		},
		async updateIndex(){
			await this.checkIDs()
			await this.getPaginated(false);
		},

		async handleRedirectMethod(params: any, newWindow: boolean = true){
			if(params.column == "advertiser_name"){
				let line = this.getEntities.find((i) => i.id == params.id);
				if(!isNull(line)){
					let obj = {
						name: "AdvertiserEdit",
						params: {
							id: line.advertiser_id
						}
					}

					/**
					 * Open in a new windows just when is Ctrl + Click
					 */
					if(newWindow) {
						const routeData = this.$router.resolve(obj);
						window.open(routeData.href, '_blank');
					} else {
						this.$router.push(obj);
					}
				}
			}
			if(params.column == "campaign_name"){
				let line = this.getEntities.find((i) => i.id == params.id);
				if(!isNull(line)){
					let obj = {
						name: "CampaignEdit",
						params: {
							id: line.campaign_id
						}
					}

					/**
					 * Open in a new windows just when is Ctrl + Click
					 */
					if(newWindow) {
						const routeData = this.$router.resolve(obj);
						window.open(routeData.href, '_blank');
					} else {
						this.$router.push(obj);
					}
				}
			}
		},
		async handlerCancelDelete(){
			this.optionDelete.open = false;
			this.optionDelete.id = null;
		},

		async handlerDeleteLineItemByID(id: Number){
			await this.setLoadingData(TypeLoading.loading);
			await this.deleteLineItemByID(id)
			.then(async (result) => {
				await this.setLoadingData();
				await this.handlerCancelDelete();
				await this.getPaginated(false);
			}).catch(async (err) => {
				await this.setLoadingData();
				await this.handlerCancelDelete();
			});
		},

		async deleteByID(params: any){
			this.optionDelete.open = true;
			this.optionDelete.id = params.id;
			this.optionDelete.err = false;

			let msg = `${i18n.t("delete_msg.line_item.msg_type_one")} ${params.name} (ID: ${params.ext_id})?`

			this.optionDelete.msg = msg;
			this.optionDelete.sub_msg = i18n.t("delete_msg.line_item.sub_msg");

			/*
			let lineItemData = this.getEntities.find(c => c.id == params.id);
			if(!isNull(lineItemData)){
				if(lineItemData.creative_associations_count > 0){
					this.optionDelete.err = true;
					this.optionDelete.sub_msg = "";
					this.optionDelete.msg = i18n.t("delete_msg.line_item.msg_type_two");
				}
				if(Number(lineItemData.spend) > 0){
					this.optionDelete.err = true;
					this.optionDelete.sub_msg = "";
					this.optionDelete.msg = i18n.t("delete_msg.line_item.msg_type_three");
				}
			}*/
		}
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
		"getTo"(val, old){
			this.updateIndex();
		}
	},
});
