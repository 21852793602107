
import { isEmpty } from "lodash";
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import { SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";

export default Vue.extend({
	name: "TableList",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: function () {
				return [];
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		limit: {
			type: Number,
			default: 25,
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		bulk: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: { Filterable, IconBase, CardSwitch },
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},
		selectedIds: {
			set(val) {
				this.$emit("update-selected", val);
			},
			get() {
				return this.bulk.selected;
			},
		},
		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},
	},

	methods: {
		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},
		getActiveText(active: Boolean) {
			return active
				? i18n.t("common.fields.active")
				: i18n.t("common.fields.inactive");
		},
		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},
		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},
		removeOption() {
			this.$emit("remove-option");
		},

		getWinningNow(value: number): string {
			return value == 0 ? "NO" : "YES";
		},

		rowClick(item: any, slot: any, type: any, event: any) {
			if (event && event.event) {
				event?.event?.preventDefault();
				event?.event?.stopPropagation();
			}

			switch (type) {
				case "line":
					this.$router.push({
						name: "LineItemEdit",
						params: { id: item.id },
						query: { section: 3 }, 
					});
					event?.stopPropagation();
					break;
				case "active":
					this.$emit("set-active", {
						id: item.id,
						active: item.active,
					});
					break;
				case "row":
					this.$router.push({
						name: "LineItemEdit",
						params: { id: item.id },
					});
					event?.stopPropagation();
					break;
				case "duplicated":
					this.$emit("open-dialog", {
						id: item.id,
						external_id: item.external_id,
					});
					event?.stopPropagation();
					break;
				case "activityLog":
					this.$router.push({
						name:"ActivityLogLineItem" ,
						query: { id: item.id }
					});
					event?.stopPropagation();
					break;
			}
		},

		setIncativeLineItem(id: number) {
			this.items.find((v: { id: number }) => v.id == id).active = false;
		},
	},
});
